.endpointsFormWrapper {
  --palette-text-default: var(--grey-body);
}

.row {
  display: flex;
  justify-content: space-between;
}

.endpointsFormSection {
  padding: 24px 24px 0 24px;

  border-radius: 8px;

  background-color: var(--palette-default);
}

.endpointsFormSection:first-child {
  margin-bottom: 12px;
}

.dateInputsContainer [class*="inputsWrapper"] {
  gap: 17px;
}

.title,
.fileSetUpTitle {
  margin-bottom: 8px;

  text-transform: uppercase;

  color: var(--grey-body);

  font-weight: 600;
}

.fileSetUpTitle {
  margin-bottom: 16px;
}

.switchersWrapper {
  display: flex;

  margin-bottom: 16px;

  gap: 120px;
}

.endpointsFormSection .switchInput label span {
  color: var(--palette-text-primary);
}
