.fileNameWrap {
  max-width: 200px;
}

.dateCell img {
  margin-right: 6px;
  margin-left: 10px;
}

.checkboxCell.override:first-of-type {
  margin-right: 0;
}

.headersCell {
  min-width: 150px;
  max-width: 150px;
}
