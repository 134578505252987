.container {
  display: flex;
  align-items: center;

  width: 100%;
  column-gap: 24px;

  padding: 26px 48px 28px;
}

.spacer {
  flex: 1 1 auto;
}

.title {
  display: flex;
  align-items: center;

  color: var(--palette-text-default);

  font-size: 22px;
  font-weight: 500;
  font-style: normal;
}

.container button {
  width: 36px;
  padding: 0;
}

/* Menu */
.menu {
  display: grid;

  padding: 4px;
}

.item {
  padding: 6px 20px;

  color: var(--palette-text-default);
  border: 0;
  border-radius: 8px;
  background: transparent;
  box-shadow: none;

  font-weight: 500;
}

.item:hover {
  background: var(--palette-default);
}

.item:focus,
.item:active {
  color: var(--palette-primary-disabled);
}

/* Hamburger button */
.container .hamburger {
  display: none;
}

@media (max-width: 992px) {
  .container {
    background-color: var(--palette-default);
  }

  .container .hamburger {
    display: flex;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 26px 24px 28px;
    column-gap: 12px;
  }

  .title {
    font-size: 17px;
  }
}

@media (max-width: 450px) {
  .container .menuTrigger {
    display: none;
  }
}

@media (max-width: 520px) {
  .title {
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .title {
    font-size: 12px;
  }
}
