.infoPanelWrap {
  position: relative;

  min-height: 400px;
  padding: 10px 24px 24px;

  color: var(--palette-text-default);
  border-radius: 20px;
  background: var(--palette-default);
}

.infoPanelItem {
  display: flex;
  flex-direction: column;

  margin-top: 16px;
  gap: 2px;
}

.label {
  color: var(--palette-text-secondary);

  font-weight: 600;
}

.filesCount {
  color: var(--palette-text-secondary);

  font-size: 12px;
  line-height: 18px;
}

/*  JobCaptionNote  */
.captionNoteWrap {
  display: flex;
  align-items: center;

  margin-bottom: 5px;
}

.captionNoteId {
  flex: 1 1 auto;

  margin-right: 6px;

  color: var(--palette-primary-disabled);
}

.captionNoteWrap .captionNoteButton {
  height: initial;
  margin-left: 6px;
  padding: 4px 8px;
}

/*  JobFileName  */
.jobFileNameWrap {
  -webkit-line-clamp: 1;

  margin-bottom: 24px;
}

.jobFileNameLabel {
  padding: 0 8px;
}

.jobFileName {
  width: 100%;
}

/*  JobTargetUrlName  */
.jobTargetUrlNameWrap {
  margin-bottom: 24px;
}

.jobTargetUrlLabel {
  padding: 0 6px;
}

/*  JobDates  */
.jobDatesWrap {
  margin-top: 5px;
  margin-bottom: 6px;
}

.jobDateWrap {
  display: flex;
  align-items: center;

  margin-top: 4px;
}

.jobDateWrap img {
  width: auto;
  height: 11px;

  user-select: none;
}

.jobDateWrap:not(:last-child) {
  margin-bottom: 8px;
}

.dayInfo {
  min-width: 110px;
  margin: 0 6px 0 8px;

  font-size: 12px;
  line-height: 13px;
}

.timeInfo {
  margin-left: 6px;

  font-size: 12px;
  line-height: 13px;
}

/*  JobStatus  */
.jobStatusWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: 14px;
}

.jobStatusCircleWrap {
  display: flex;
  align-items: center;
}

.jobStatusCircularProgress {
  margin-right: 5px;
}

.jobStatusInfoText {
  margin: 0;
  padding: 0 6px;
}

/*  JobActions  */
.actionsTitle {
  margin: 0;

  color: var(--palette-grey-active);

  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
}

.actionsBtnWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.actionButton.override {
  padding: 8px 5px;

  background: var(--button-transparent-effect-background);
}

.reconfigureButton svg circle,
.reconfigureButton:hover svg circle,
.reconfigureButton:active svg circle,
.reconfigureButton:focus svg circle {
  fill: none;
}

.backActionButton {
  padding: 0 4px;
}

.tooltipContent {
  width: 284px;
}

.infoPanelUrl {
  display: flex;
  gap: 4px;

  align-items: center;
}

.copyIcon {
  cursor: pointer;
}

.tooltipContent {
  max-width: 138px;
}

.urlKey {
  color: var(--palette-text-primary);
}

.urlValue {
  color: var(--palette-primary-active);
}

.urlValue:last-of-type .urlSymbol {
  display: none;
}

.urlValueParam {
  color: var(--palette-warning);
}

@media (max-width: 768px) {
  .infoPanelWrap {
    min-height: auto;
    padding: 10px;
  }

  .jobStatusWrap {
    margin-bottom: 24px;
  }
}
