.month {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 118px;

  height: 12vw;
  max-height: 58px;

  margin-left: 0;
  padding: 6px 12px;

  list-style: none;

  border-right: var(--borderStyle);
  border-bottom: var(--borderStyle);
  background-color: var(--palette-shadow-tiny);
}

.monthActive {
  background-color: var(--palette-default);
}

.monthName,
.day {
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--palette-grey-hover);

  font-size: 10px;
  font-weight: normal;
  line-height: 1.5;
}

.dayHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 15px;
}

.monthName {
  left: 12px;
}

.day {
  right: 12px;

  margin-left: auto;
}

.monthInput {
  width: 100%;
  height: 31px;
  margin-left: auto;
  padding-top: 7px;

  text-align: right;

  color: var(--palette-primary);
  border: none;
  border-bottom: 1px solid var(--palette-grey-disabled);

  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
}

.monthInput:focus {
  border-bottom: 1px solid var(--palette-primary-focus);
}

.monthInput.error {
  border-bottom: 1px solid var(--palette-error);
}

.monthInput.disabled {
  color: var(--palette-grey-main);
}
